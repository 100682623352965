<template>

  <div>

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->

    <b-card no-body>
    
      <b-card-body>
        <b-row>
          

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="years"
              class="w-100"
              placeholder="Select Year"
              v-model="current_Year"
              @input="filterTable"

            />
          </b-col>

          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              
              <b-button
                variant="warning"
                @click="openModel"
              >
                <span class="text-nowrap">Import</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
        
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="filterTable"
              />

              <b-button variant="warning" class="mobile-margin-bottom" @click="openModal(null, 'add')">
                  <span class="text-nowrap">Add Holiday</span>
              </b-button>
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        <template #cell(date)="items">           
            <span class="wordBreak">
              <div class="my-1">{{ items.item.date | date}}</div>
            </span>         
        </template>

        <!-- Column: Role -->
        <template #cell(day)="items">           
            <span class="wordBreak">{{ items.item.day | capitalize}}</span>         
        </template>

        <template #cell(holiday)="items">           
            <span class="wordBreak">{{ items.item.holiday | capitalize}}</span>         
        </template>

        <template #cell(actions)="items">
            <!-- Edit button -->
            <b-link 
              v-b-tooltip.hover.v-warning
              title="Edit" variant="outline-warning"
              @click="openModal(items.item, 'edit'); form.id = items.item._id; form.holiday = items.item.holiday; form.date = items.item.date"
            >
                <feather-icon icon="EditIcon" class="mr-1 mediumSize"/>
            </b-link>
            
            <!-- Delete button -->
            <b-link 
              v-b-tooltip.hover.v-warning
              title="Delete" variant="outline-warning"
              @click="deleteItem(items.item._id)"
            >
                <feather-icon icon="Trash2Icon" class="mediumSize"/>
            </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="import"
      ref="import"
      cancel-variant="outline-secondary"
      ok-variant="outline-warning"
      ok-title="Submit"
      cancel-title="Close"
      centered
      title="Import Public Holiday"
      no-close-on-backdrop
      @ok="uploadFile"
    >

    <b-row>
      <b-col md="12">
        <b-button
          variant="danger"
          align="right"
          class="float-right"
          @click="sampleDownload"
        >
          <span class="text-nowrap">Sample</span>
        </b-button>

      </b-col>
    </b-row>
    

      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1 mt-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

      <b-form>      
    
        <b-row>
          
          
          <b-col md="12">
            <b-form-group
              label="Upload File Template"
              
              class="required mb-1"
            >
              <b-form-file
                id="upload_file"
                ref="uploadFile"
                accept=".csv"
                @change="onFileChange"
                v-model="bulkFile"
              />
              <small class="text-danger">supported format: .csv</small>
          </b-form-group>
          </b-col>
        </b-row>
      
        <!-- submit and reset -->
    
      </b-form>
    </b-modal>

    <!-- Holiday Form Modal -->
    <b-modal 
        id="add-holiday" 
        centered ref="add-holiday" 
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        :title="title"
        @show="resetForm"
        @hidden="resetForm"
        @ok="addDevice"
        no-close-on-backdrop
        size="md"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body"> {{error_message}} </div>
        </b-alert>

        <b-form>
            <b-row>

                <b-col md="12">
                    <b-form-group label="Holiday Name" class="required">
                        <b-form-input placeholder="Enter Holiday Name" v-model="form.holiday" autocomplete="off"/>
                    </b-form-group>
                </b-col>
                
                <b-col md="12">
                    <b-form-group label="Date" class="required">
                        <b-form-datepicker v-model="form.date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en"/>
                    </b-form-group>
                </b-col>

            </b-row>      
        </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBreadcrumb,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,
  BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, 
  BFormCheckbox,BCardBody, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BBreadcrumb,
    BFormDatepicker
  },
  directives: { 'b-tooltip': VBTooltip },
  data() {
    return {
       tableColumns: [
            { key: 'date', label: 'Date', sortable: true , thStyle:  {width: '30%'}},
            { key: 'day', label: 'Day', sortable: true , thStyle:  {width: '25%'}},
            { key: 'holiday', label: 'Holiday', sortable: true , thStyle:  {width: '30%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
          ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'date',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        current_Year:moment().format('YYYY'),
        years:[],
        selectedFile:{
          file:null,
          name:'',
        },
        bulkFile:null,
        error_message:null,
        showDismissibleAlert:false,

        // model vars
        title: null,
        form: {
            id      : '',
            holiday : '',
            date    : moment().tz('Asia/Singapore').toDate()
        }

    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     year:this.current_Year,
                   },
                   api: '/api/holiday-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    calYear(){
      
      var year = parseInt(moment().format('YYYY')) - 1;
      
      this.years = [];
      var i = 0;
      while(i<=2){
        this.years.push(year);
        year = year +1
        i = i+1;
      }
    },
    openModel(){

      this.showDismissibleAlert = false;

      this.selectedFile = {
          file:null,
          name:'',
        };
      
      this.$refs['import'].show();
    },
    onFileChange(event) {
            
      this.selectedFile.file = null;
      this.selectedFile.name = '';
      var input = event.target;
      
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var file = input.files[0];
          
          console.log(file);
          if ((file.type != 'application/vnd.ms-excel' && file.type != 'text/csv') ) {
              
              this.selectedFile.file = null;
              this.selectedFile.name = '';
              
              
              this.$refs['uploadFile'].reset()
              this.bulkFile = null;

              this.showDismissibleAlert=false;
              Swal.fire({
                icon: 'error',
                title: "Please upload .csv files",
              })
          } else if (file.size > 2097152) {

              file="";
              this.selectedFile.file = null;
              this.selectedFile.name = '';
              this.showDismissibleAlert=false;
              this.$refs['uploadFile'].reset()
              this.bulkFile = null;

              Swal.fire({
                icon: 'error',
                title: "Maximum 20 MB files are allowed to upload"
              })
          } else {
              reader.onload = (e) => {
                  
                  this.selectedFile.file = e.target.result;
                  this.selectedFile.name = file.name;

                  this.showDismissibleAlert=false;
              }
          }
      }
    },
    uploadFile(e){
      e.preventDefault();
      
      return this.$store.dispatch(POST_API, {
           data:{
             selectedfile:this.selectedFile,
             
           },
           api: '/api/import-holiday'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                this.$refs.uploadFile.value=null;
                this.selectedFile.file = null;
                this.selectedFile.name = '';
                this.bulkFile = null;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Holiday Imported Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    

                    this.filterTable();
                    this.$refs['import'].hide();
                    ;
                });
                
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Holiday',
        active:true             
      }];
      return item;
    },

    openModal(data, mode){
      this.showDismissibleAlert = false;
        this.title = mode == 'edit' ? 'Edit Holiday' : 'Add Holiday';
        this.$refs['add-holiday'].show();
    },

    resetForm(){
        this.form.id      = '';
        this.form.holiday = '';
        this.form.date    = moment().tz('Asia/Singapore').toDate();
    },

    addDevice(e){
        e.preventDefault();
        return this.$store.dispatch(POST_API, {
            data:{
                id      : this.form.id,
                holiday : this.form.holiday,
                date    : moment(this.form.date).format('YYYY-MM-DD'),
                day     : moment(this.form.date).format('dddd')
            },
            api : this.form.id ? "/api/edit-holiday" : "/api/add-holiday",
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.$refs['add-holiday'].hide();
                this.successAlert();
                this.filterTable();
            }
        });
    },

    deleteItem(id){
        var msg = 'Are you sure want to delete this record?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{ id : id },
                    api : "/api/delete-holiday",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        this.successAlert();

                        this.$refs.refUserListTable.refresh();
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },
    sampleDownload(){
      var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/holiday_sample.csv';
      window.open(url, '_blank');
    },
   
  },
  mounted(){
    this.calYear();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
